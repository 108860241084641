import React from "react";
import "../styles/SuccessPage.css";
import Accordion from "react-bootstrap/Accordion";

function SuccessPage() {
    return (
        <div className="Success-container">
            <h1 className="Success-title">Check your email!</h1>
            <p className="Success-description">
                There is a confirmation email waiting for you in your inbox.
            </p>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Don't see the email? Click here.
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="Inbox-info">
                            <p>
                                The email may be in your <b>spam</b> or{" "}
                                <b>promotions</b> folder. Follow these steps to
                                move it to your inbox:
                            </p>
                            <h2>Gmail</h2>
                            <ol>
                                <li>Open Gmail and log in to your account.</li>
                                <li>
                                    Find the email:
                                    <ul>
                                        <li>
                                            <strong>Promotions Folder:</strong>{" "}
                                            Click on the "Promotions" tab.
                                        </li>
                                        <li>
                                            <strong>Spam Folder:</strong> Click
                                            on the "More" button on the left
                                            sidebar, then select "Spam."
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Select the email by clicking the checkbox
                                    next to it.
                                </li>
                                <li>
                                    Move to Inbox:
                                    <ul>
                                        <li>
                                            <strong>Promotions Folder:</strong>{" "}
                                            Click on the "Move to Inbox" button
                                            at the top.
                                        </li>
                                        <li>
                                            <strong>Spam Folder:</strong> Click
                                            on the "Not Spam" button at the top.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Check your "Primary" tab to ensure the email
                                    has been moved.
                                </li>
                            </ol>

                            <h2>Outlook</h2>
                            <ol>
                                <li>
                                    Open Outlook and log in to your account.
                                </li>
                                <li>
                                    Find the email:
                                    <ul>
                                        <li>
                                            <strong>
                                                Other or Junk Folder:
                                            </strong>{" "}
                                            Click on "Other" or "Junk Email"
                                            under the "Folders" section.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Select the email by right-clicking on it.
                                </li>
                                <li>
                                    Move to Inbox:
                                    <ul>
                                        <li>
                                            <strong>Other Folder:</strong>{" "}
                                            Select "Move to Focused Inbox" from
                                            the dropdown menu.
                                        </li>
                                        <li>
                                            <strong>Junk Email Folder:</strong>{" "}
                                            Select "Mark as not junk" from the
                                            dropdown menu. Then, choose "Move to
                                            Inbox" if prompted.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Return to your "Focused" Inbox to ensure the
                                    email has been moved.
                                </li>
                            </ol>

                            <h2>iCloud Mail</h2>
                            <ol>
                                <li>
                                    Open iCloud Mail and log in to your account.
                                </li>
                                <li>
                                    Find the email in the "Junk" folder in the
                                    left sidebar.
                                </li>
                                <li>
                                    Select the email by clicking the checkbox
                                    next to it.
                                </li>
                                <li>
                                    Move to Inbox:
                                    <ul>
                                        <li>
                                            Click on the "Move to" button at the
                                            top of the email list.
                                        </li>
                                        <li>
                                            Select "Inbox" from the dropdown
                                            menu.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Go back to your "Inbox" to ensure the email
                                    has been moved.
                                </li>
                            </ol>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default SuccessPage;

import axios from "axios";

async function preflightRequest() {
    try {
        const response = await axios.options(
            "https://is4d24daz4.execute-api.us-east-1.amazonaws.com/v1/handleSubscribe"
        );

        return response;
    } catch (error) {
        console.error("Preflight request failed:", error);
        throw error;
    }
}

async function postEmail(emailInput) {
    try {
        const response = await axios.post(
            "https://is4d24daz4.execute-api.us-east-1.amazonaws.com/v1/handleSubscribe",
            {
                email: emailInput,
            }
        );

        return response;
    } catch (error) {
        console.error("Email post request failed:", error);
        throw error;
    }
}

export { preflightRequest, postEmail };

import React from "react";
import { Link } from "react-router-dom";
import "../styles/ExamplePage.css";
import logo from "../assets/logo.png";

function ExamplePage() {
    return (
        <div className="Email-client">
            <div className="Header">
                <div className="Logo-container">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="Logo" />
                    </Link>
                </div>
                <h1>
                    The content you can expect from The Daily Loop Newsletter
                </h1>
            </div>
            <div className="Email-window">
                <div className="Example-container">
                    <h1 className="Example-title">The Daily Loop</h1>
                    <div className="Example-description">
                        <p style={{ textAlign: "center" }}>
                            <strong>July 5th, 2024</strong>
                        </p>
                        <h2>UK Labour Party Heads for Landslide Victory</h2>
                        <p>
                            Britain's Labour Party is set for a landslide
                            victory in the parliamentary election, with exit
                            polls and partial returns indicating a significant
                            lead over the Conservatives. As vote counting
                            continued, Labour secured 326 of the 650 seats,
                            signaling a dramatic shift in political power. Prime
                            Minister Rishi Sunak conceded defeat and
                            congratulated Labour leader Keir Starmer, who will
                            face the challenge of addressing economic malaise
                            and societal distrust. The Conservatives, after 14
                            years in power, are left in disarray and may undergo
                            a leadership contest. This election is poised to be
                            one of the most consequential since World War II.
                        </p>

                        <h2>
                            Ceasefire Talks in Gaza Show Signs of Progress Amid
                            Rising Tensions
                        </h2>
                        <p>
                            Israeli officials express "cautious optimism" as
                            ceasefire negotiations with Hamas are revived.
                            Mossad chief David Barnea will lead a delegation to
                            Doha to meet with Qatar's prime minister, following
                            reports of a potential breakthrough. However,
                            significant issues remain unresolved. In Gaza,
                            Nasser Hospital and the Kuwaiti Field Hospital near
                            Rafah are set to close as fuel for generators runs
                            out. Meanwhile, Hezbollah has launched over 200
                            rockets and attack drones into northern Israel in
                            retaliation for the killing of their senior
                            commander.
                        </p>

                        <h2>
                            EU Imposes Tariffs on Chinese Electric Vehicles Amid
                            Trade Tensions
                        </h2>
                        <p>
                            The European Union will impose tariffs of up to
                            37.6% on imports of electric vehicles (EVs) made in
                            China starting Friday, escalating tensions between
                            the two regions. The provisional tariffs aim to
                            counteract what the EU perceives as unfair
                            competition from state-subsidized Chinese EVs. This
                            move follows reports of a possible breakthrough in
                            ceasefire talks between Israel and Hamas, with key
                            issues still unresolved. The EU's trade case, its
                            largest to date, could lead to definitive duties
                            after a four-month investigation period. Beijing has
                            threatened retaliation, potentially affecting
                            exports like cognac and pork.
                            <br></br>
                            <br></br>
                            <i>
                                Context: The EU's move mirrors past issues with
                                Chinese solar panel imports, leading to the
                                collapse of many European manufacturers.
                            </i>
                        </p>

                        <h2>
                            Google's Struggle to Meet Net Zero Climate Goal by
                            2030
                        </h2>
                        <p>
                            Google set an ambitious goal to become "net zero" by
                            2030, meaning it would balance its emissions with
                            measures to remove climate-changing gases. However,
                            a recent report shows Google is not on track, with
                            emissions increasing by 13% in 2023 compared to the
                            previous year and 48% since 2019. The rise in
                            emissions is attributed to the high electricity
                            demand from data centers required for artificial
                            intelligence. Google's efforts to achieve net zero
                            are further complicated by the growing global
                            electricity demand for data centers, potentially
                            doubling by 2026. Despite these challenges, Google
                            has increased its use of renewable energy, achieving
                            64% carbon-free energy for its data centers and
                            offices in 2023.
                            <br></br>
                            <br></br>
                            <i>
                                Context: The power demand from data centers,
                                driven largely by AI, is expected to increase by
                                160% by 2030. Data centers currently account for
                                1-2% of global electricity usage, but this could
                                rise to 3-4% by the end of the decade.
                            </i>
                        </p>

                        <h2>
                            Nvidia CEO Highlights AI Chip Strategy and Future
                            Growth at Computex 2024
                        </h2>
                        <p>
                            Nvidia CEO Jensen Huang emphasized the company's
                            strategic investment in artificial intelligence (AI)
                            over the past decade during his speech at Computex
                            2024 in Taipei. Nvidia's significant investment in
                            AI and a robust team of engineers have positioned
                            the company as a leader in the AI chip market.
                            Despite increased competition, Huang outlined
                            Nvidia's strategies to maintain its dominance,
                            focusing on data center transformation, expansion
                            into new markets, and broad partnerships with
                            computer makers and cloud providers.
                            <br></br>
                            <br></br>
                            <i>
                                Context: Nvidia holds more than 80% market share
                                in AI chips.
                            </i>
                        </p>
                        <br></br>
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            Thanks for reading! If you'd like to leave some
                            feedback, just respond to this email.
                        </p>
                        <br></br>
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            Unsubscribe to stop receiving this newsletter.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            Loop News, LLC - 16192 Coastal Highway, Lewes,
                            Delaware, 19958, United States of America
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExamplePage;

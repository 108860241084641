import React from "react";
import "../styles/PrivacyPage.css";

function PrivacyPage() {
    return (
        <div className="Privacy-container">
            <h1 className="Privacy-title">Privacy Policy</h1>
            <div className="Privacy-description">
                <p style={{ textAlign: "center" }}>
                    <strong>Effective Date: 8/3/2024</strong>
                </p>
                <p style={{ textAlign: "center" }}>
                    Welcome to Loop News LLC. This Privacy Policy explains how
                    we collect, use, disclose, and safeguard your information
                    when you subscribe to our daily newsletter via our website.
                    Please read this privacy policy carefully. If you do not
                    agree with the terms of this privacy policy, please do not
                    access the site or subscribe to the newsletter.
                </p>

                <h2 className="Privacy-section">1. Business Information</h2>
                <p>
                    Loop News LLC is a limited liability company registered in
                    Delaware, USA. We operate the website where users can
                    subscribe to receive daily newsletters.
                </p>

                <h2 className="Privacy-section">2. Data Collection</h2>
                <p>
                    We collect information that you provide to us when you
                    subscribe to our newsletter. The types of information we may
                    collect include:
                </p>
                <ul>
                    <li>
                        Email Addresses: To communicate with you regarding the
                        newsletter and other notices.
                    </li>
                    <li>
                        IP Addresses: For security and troubleshooting purposes.
                    </li>
                    <li>
                        Interaction Records: Including clicks on sponsored links
                        or specific articles, for analytics and to enhance your
                        user experience.
                    </li>
                </ul>

                <h2 className="Privacy-section">3. Data Usage</h2>
                <p>We use your information for the following purposes:</p>
                <ul>
                    <li>To send you the daily newsletter.</li>
                    <li>
                        For analytics to understand how our users interact with
                        the newsletters and improve our offerings.
                    </li>
                    <li>To ensure the security of our services.</li>
                </ul>

                <h2 className="Privacy-section">4. Data Sharing</h2>
                <p>
                    We do not share or sell your personal data with any third
                    parties.
                </p>

                <h2 className="Privacy-section">5. User Rights and Choices</h2>
                <p>
                    You have the following rights concerning your personal data:
                </p>
                <ul>
                    <li>
                        Access: You can request details of personal data we hold
                        about you.
                    </li>
                    <li>
                        Rectification: You can ask us to correct or complete any
                        inaccurate or incomplete data.
                    </li>
                    <li>
                        Deletion: You may request the deletion of your personal
                        data, subject to certain exceptions prescribed by law.
                    </li>
                </ul>
                <p>
                    To exercise these rights, please contact us at
                    support@loop-newsletter.com with your request.
                </p>

                <h2 className="Privacy-section">6. Data Security</h2>
                <p>
                    We implement technical, administrative, and physical
                    security measures to protect your personal information from
                    unauthorized access, use, or disclosure. Despite our
                    efforts, no security measures are perfect or impenetrable,
                    and no method of data transmission can be guaranteed against
                    any interception or other type of misuse.
                </p>

                <h2 className="Privacy-section">7. Data Retention</h2>
                <p>
                    We retain your personal data as long as necessary to send
                    you the newsletter unless a longer retention period is
                    required or permitted by law.
                </p>

                <h2 className="Privacy-section">8. Cookies and Tracking</h2>
                <p>
                    We do not use cookies or tracking technologies on our
                    newsletter or website at this time.
                </p>

                <h2 className="Privacy-section">9. Policy Updates</h2>
                <p>
                    We may update this privacy policy from time to time. We will
                    notify you of any changes by posting the new privacy policy
                    on this page and updating the "Effective Date" at the top of
                    this policy. We may also inform you via email or other
                    direct communication methods.
                </p>

                <h2 className="Privacy-section">10. Contact Information</h2>
                <p>
                    If you have questions or concerns about this privacy policy,
                    please contact us at support@loop-newsletter.com.
                </p>

                <h2 className="Privacy-section">11. Use by Minors</h2>
                <p>
                    Our services are intended for adult users. We do not
                    knowingly collect information from anyone under the age of
                    majority in their jurisdiction. If you are under 16, please
                    do not use our services. If we learn that we have
                    inadvertently collected information from a child under 16,
                    we will delete that information as soon as possible.
                </p>

                <h2 className="Privacy-section">
                    12. Your California Privacy Rights (CCPA)
                </h2>
                <p>
                    If you are a California resident, you have specific rights
                    regarding your personal data. These include the rights to
                    request disclosure of the categories and specific pieces of
                    personal data we have collected, the right to deletion of
                    your personal data, and the right to opt out of the sale of
                    your personal data. We do not discriminate against users who
                    exercise their CCPA rights.
                </p>

                <h2 className="Privacy-section">13. CAN-SPAM Compliance</h2>
                <p>
                    We adhere to the requirements of the CAN-SPAM Act. Our
                    emails provide a clear mechanism for unsubscribing, include
                    our business’s physical postal address, and ensure that the
                    subject lines accurately reflect the content of the message.
                    You can unsubscribe at any time by following the
                    instructions in each email.
                </p>
                <br></br>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                    Loop News, LLC - 16192 Coastal Highway, Lewes, Delaware,
                    19958, United States of America
                </p>
            </div>
        </div>
    );
}

export default PrivacyPage;

import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import "../styles/ConfirmPage.css";

function ConfirmPage() {
    const location = useLocation();
    const [status, setStatus] = useState("Confirming your email...");
    const [isSuccess, setIsSuccess] = useState(null);
    const [showReloadButton, setShowReloadButton] = useState(false);
    const hasSentRequest = useRef(false); // To prevent multiple requests

    useEffect(() => {
        if (hasSentRequest.current) {
            return; // Exit if the request has already been sent
        }
        hasSentRequest.current = true;

        // Parse the query string to get the token
        const params = new URLSearchParams(location.search);
        const token = params.get("token");

        if (token) {
            // Send request lambda to validate token
            axios
                .post(
                    "https://is4d24daz4.execute-api.us-east-1.amazonaws.com/v1/handleConfirm",
                    { token }
                )
                .then((response) => {
                    const data = response.data;
                    if (data.statusCode === 200) {
                        setStatus(
                            "Your email has been confirmed! See you soon."
                        );
                        setIsSuccess(true);
                    } else {
                        setStatus(
                            "Invalid or expired token. Please try again."
                        );
                        setIsSuccess(false);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setStatus(
                        "An error occurred while confirming your email. Please refresh the page and try again."
                    );
                    setIsSuccess(false);
                    setShowReloadButton(true); // Show reload button in case of error
                });
        } else {
            setStatus("Invalid request. No token provided.");
            setIsSuccess(false);
        }
    }, [location.search]);

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <div className="Confirm-container">
            {isSuccess === true && <h1 className="Confirm-title">Success!</h1>}
            {isSuccess === false && (
                <h1 className="Confirm-title-unsuccessful">Oops...</h1>
            )}
            <p className="Confirm-description">{status}</p>
            {isSuccess === false && showReloadButton && (
                <button
                    type="button"
                    className="Home-button"
                    onClick={reloadPage}
                >
                    Reload page
                </button>
            )}
            {isSuccess === false && !showReloadButton && (
                <p>
                    <Link to="/">
                        <button type="button" className="Home-button">
                            Sign-up here
                        </button>
                    </Link>
                </p>
            )}
        </div>
    );
}

export default ConfirmPage;

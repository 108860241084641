import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../styles/ConfirmPage.css";

function UnsubscribePage() {
    const location = useLocation();
    const [email, setEmail] = useState(null);
    const [confirmationToken, setConfirmationToken] = useState(null);
    const [status, setStatus] = useState("Processing request...");
    const [isSuccess, setIsSuccess] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        // Parse the query string to get the token
        const params = new URLSearchParams(location.search);
        setEmail(params.get("email"));
        setConfirmationToken(params.get("confirmationToken"));
    }, [location.search]);

    const sendRequest = () => {
        setRequestSent(true);
        setStatus("Processing request...");
        if (email && confirmationToken) {
            // Send request to lambda to validate token
            axios
                .post(
                    "https://is4d24daz4.execute-api.us-east-1.amazonaws.com/v1/handleConfirmEmailUnsubscribe",
                    { email: email, confirmationToken: confirmationToken }
                )
                .then((response) => {
                    const data = response.data;
                    if (data.statusCode === 200) {
                        setStatus(
                            "Your email has been removed from the mailing list."
                        );
                        setIsSuccess(true);
                    } else {
                        setStatus(
                            "Invalid or expired token. Please note that if you have not verified your email within 48 hours then you have been unsubscribed automatically."
                        );
                        setIsSuccess(false);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setStatus(
                        "An error occurred while processing. Please refresh the page and try again."
                    );
                    setIsSuccess(false);
                });
        } else {
            setStatus("Invalid request. No token or email provided.");
            setIsSuccess(false);
        }
    };

    return (
        <div className="Confirm-container">
            {requestSent === false && (
                <>
                    <h1 className="Unsubscribe-title">Unsubscribe</h1>
                    <p className="Confirm-description">
                        Please confirm that you want to unsubscribe by checking
                        the box below and clicking "Unsubscribe".
                    </p>
                    <label className="Unsubscribe-checkbox-label">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                        />{" "}
                        I confirm that I want to unsubscribe.
                    </label>
                    <button
                        type="button"
                        className="Home-button"
                        onClick={() => sendRequest()}
                        disabled={!isChecked} // Disable the button unless the box is checked
                    >
                        Unsubscribe
                    </button>
                </>
            )}
            {requestSent === true && isSuccess === null && (
                <p className="Confirm-description">{status}</p>
            )}
            {isSuccess === true && (
                <>
                    <h1 className="Unsubscribe-title">
                        Unsubscribe Successful
                    </h1>
                    <p className="Confirm-description">{status}</p>
                </>
            )}
            {isSuccess === false && (
                <>
                    <h1 className="Unsubscribe-title">Oops...</h1>
                    <p className="Confirm-description">{status}</p>
                </>
            )}
        </div>
    );
}

export default UnsubscribePage;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { preflightRequest, postEmail } from "../postEmail";
import landingImage from "../assets/landing-image-alt.png";
import logo from "../assets/logo_light.png";
import "../styles/Main.css";
import "../styles/Global.css";

function MainPage() {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(
        "We'll never share your information with anyone."
    );
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const validateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            setErrorMessage("Please enter a valid email address.");
            return false;
        }
        return true;
    };

    const handleSubscribe = async (e) => {
        e.preventDefault(); // Prevent form submission
        setErrorMessage("");
        if (validateEmail()) {
            try {
                setIsLoading(true);

                // Perform preflight request
                const preflightResponse = await preflightRequest();
                if (preflightResponse.status !== 200) {
                    setErrorMessage(
                        "Preflight check failed. Please try again."
                    );
                    return;
                }

                const response = await postEmail(email);

                // Check if the response is OK (status code 200)
                if (response.status === 200) {
                    const responseData = response.data; // Extract the data part of the response
                    let responseBody = {};

                    // Parse the JSON string in the body if it exists
                    if (responseData.body) {
                        responseBody = JSON.parse(responseData.body);
                    }

                    if (
                        responseData.statusCode === 400 ||
                        responseData.statusCode === 403
                    ) {
                        setErrorMessage(
                            "Invalid email address. Please try again."
                        );
                        console.error(
                            "Invalid email address:",
                            responseBody.error
                        );
                    } else if (responseData.statusCode === 500) {
                        setErrorMessage(
                            "There was an error processing the request. Please try again."
                        );
                        console.error("Server error:", responseBody.error);
                    } else {
                        navigate("/success");
                    }
                } else {
                    setErrorMessage("There was an error. Please try again.");
                    console.error("Failed to subscribe:", response.status);
                }
            } catch (error) {
                setErrorMessage(
                    "There was an error processing the request. Please try again."
                );
                console.error("Error occurred during subscription:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
        setErrorMessage("");
    };

    return (
        <div className="main-container">
            <div className="main-title-container">
                <span className="main-title main-gradient">The Daily Loop</span>
                <span>
                    <img className="main-logo" src={logo} alt="Company logo" />
                </span>
            </div>
            <div className="main-content-container">
                <div className="main-info-container">
                    <h1 className="main-headline font-family">
                        Stay Ahead of Your Colleagues
                    </h1>
                    <p className="main-description font-family">
                        Get Unbiased Global Insights and Business Intelligence
                        Delivered to Your Inbox Every Weekday.
                    </p>
                    <form
                        className="main-contact-container"
                        onSubmit={handleSubscribe}
                    >
                        <input
                            className="main-email"
                            type="email"
                            placeholder="Your email"
                            ref={inputRef}
                            value={email}
                            onChange={handleChange}
                            spellCheck="false"
                            autoComplete="email"
                        />
                        <button className="main-button" type="submit">
                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                "Subscribe"
                            )}
                        </button>
                    </form>
                    <p className="main-error-message">{errorMessage || " "}</p>
                </div>
                <div className="main-image-container">
                    <img
                        className="main-image main-image-round"
                        src={landingImage}
                        alt=""
                    />
                </div>
            </div>
            <div className="main-footer-container">
                <footer>
                    <Link to="/privacy">Privacy</Link>
                </footer>
            </div>
        </div>
    );
}

export default MainPage;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage";
import MainPageAlt from "./components/MainPageAlt";
import SuccessPage from "./components/SuccessPage";
import PrivacyPage from "./components/PrivacyPage";
import ConfirmPage from "./components/ConfirmPage";
import ExamplePage from "./components/ExamplePage";
import UnsubscribePage from "./components/UnsubscribePage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/signup" element={<MainPageAlt />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/confirm" element={<ConfirmPage />} />
                <Route path="/example" element={<ExamplePage />} />
                <Route path="/unsubscribe" element={<UnsubscribePage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
